import { envConstant } from '../../../constants/envConstant';
import { post_orderCreation, post_paymentConfirmation } from 'src/apis';
import routePaths from 'src/routePaths';
import { history } from 'src/store';

const loadScript = (src) => {
	return new Promise((resolve) => {
		const script = document.createElement('script');
		script.src = src;
		script.onload = () => {
			resolve(true);
		};
		script.onerror = () => {
			resolve(false);
		};
		document.body.appendChild(script);
	});
};
const makePayment = async (paymentData) => {
	await loadScript('https://checkout.razorpay.com/v1/checkout.js');
	let result;
	try {
		const data = {
			amount: paymentData.sessionPrice,
			consumerEmail: paymentData.email,
			currency: 'INR',
			eventId: paymentData.eventId,
			slotId: paymentData.slotId,
		};
		result = await post_orderCreation(data);
	} catch (e) {
		// history.push(routePaths.JIT_Home.route);
		return {
			status: 'FAILED',
		};
	}
	const { amount, orderId, currency, bookingId } = result;
	var promise = new Promise(function (resolve, reject) {
		const options = {
			key: envConstant('razorpayKey'), // Enter the Key ID generated from the Dashboard
			amount: amount,
			currency: currency,
			name: 'upGrad Mentorship',
			description: paymentData.paydescription,
			image:
				'https://ik.imagekit.io/upgrad1/marketing-platform-assets/images/brand-images/upgrad-logo.svg',
			order_id: orderId,
			retry: {
				enabled: false,
			},
			handler: async (response) => {
				const data = {
					razorpayOrderId: orderId,
					razorpayPaymentId: response.razorpay_payment_id,
					paymentSignature: response.razorpay_signature,
					bookingId: bookingId,
					paymentStatus: 'SUCCESS',
				};
				resolve(await post_paymentConfirmation(data));
			},
			prefill: {
				name: paymentData.user.name,
				email: paymentData.user.email,
				contact: paymentData.user.phoneNumber,
			},
			notes: {
				product: 'Mentorship',
				booking_id: bookingId,
			},
			theme: {
				color: '#E95454',
			},
			modal: {
				ondismiss: async () => {
					const failedData = {
						paymentFailedReason: 'Payment cancelled by user',
						paymentStatus: 'FAILED',
						bookingId: bookingId,
						razorpayOrderId: orderId,
						razorpayPaymentId: null,
					};
					await post_paymentConfirmation(failedData);
					resolve({
						status: 'CANCELLED_BY_USER',
					});
				},
			},
		};
		const paymentObject = new window.Razorpay(options);
		paymentObject.open();
		paymentObject.on('payment.failed', async function (response) {
			const failedData = {
				paymentFailedReason:
					response?.error?.reason + ' ' + response?.error?.description,
				paymentStatus: 'FAILED',
				bookingId: bookingId,
				razorpayOrderId: orderId,
				razorpayPaymentId: response?.error?.metadata?.payment_id,
			};
			resolve(await post_paymentConfirmation(failedData));
			paymentObject.close();

			const razorpayElements = document.querySelectorAll('.razorpay-container');
			razorpayElements.forEach((e) => {
				e.remove();
			});
			document.body.style.cssText = '';

			reject({
				status: 'FAILED',
			});
		});
	});
	return promise
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return {
				status: 'FAILED',
			};
		});
};
export default makePayment;
