import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
	action_loaderOff,
	action_loaderOn,
	action_setOffline,
} from 'src/actions/global';

interface type_globalReducerState {
	cohortSpecificUserLevelFlags: object;
	initialApplicationSetupDone: boolean;
	loader: number;
	offline: boolean;
	userCohortConfig: object;
	userLevelFlags: object;
}

const initialState: type_globalReducerState = {
	cohortSpecificUserLevelFlags: {},
	initialApplicationSetupDone: false,
	loader: 0,
	offline: false,
	userCohortConfig: {},
	userLevelFlags: {},
};

const globalReducer = createReducer(initialState, {
	[action_loaderOff.toString()]: (state) => {
		state.loader = state.loader > 0 ? state.loader - 1 : 0;
	},
	[action_loaderOn.toString()]: (state) => {
		state.loader += 1;
	},
	[action_setOffline.toString()]: (state, action: PayloadAction<boolean>) => {
		state.offline = action.payload;
	},
});

export type { type_globalReducerState };
export { globalReducer };
