function getDocHeight() {
	let D = document;
	return Math.max(
		D.body.scrollHeight,
		D.documentElement.scrollHeight,
		D.body.offsetHeight,
		D.documentElement.offsetHeight,
		D.body.clientHeight,
		D.documentElement.clientHeight,
	);
}

export default function pageScrollProgress() {
	let winheight =
		window.innerHeight ||
		(document.documentElement || document.body).clientHeight;
	let docheight = getDocHeight();
	let scrollTop =
		window.pageYOffset ||
		(document.documentElement || document.body.parentNode || document.body)
			.scrollTop;
	let trackLength = docheight - winheight;
	let pctScrolled = Math.floor((scrollTop / trackLength) * 100); // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
	return {
		scrollTop,
		pctScrolled,
	};
}
