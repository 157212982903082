const formatTimeDuration = (timeString) => {
	if (timeString) {
		let sessionString = '';
		const sessionDuration = timeString.split(':');
		let hourSegment = Number(sessionDuration[0]);
		let minuteSegment = Number(sessionDuration[1]);
		if (hourSegment) {
			sessionString = `${hourSegment} hr${hourSegment === 1 ? '' : 's'}`;
			if (minuteSegment) {
				sessionString = sessionString + ' ';
			}
		}
		if (minuteSegment) {
			sessionString += `${minuteSegment} mins`;
		}
		return sessionString;
	} else {
		return '';
	}
};

export default formatTimeDuration;
