import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import App from './App';
import { ErrorBoundary } from 'src/components/ErrorBoundary';

import { store, history } from './store/index';

ReactDOM.render(
	<>
		<ErrorBoundary>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<App />
				</ConnectedRouter>
			</Provider>
		</ErrorBoundary>
	</>,
	document.getElementById('root'),
);

// serviceWorker.register();
