import { authAPIAxiosInstance } from './utils/axios';
import rollbar from 'src/utils/rollbar';
import { setCookie } from 'src/utils/cookies.js';
import { cookieConstants } from 'src/constants';

const URL = {
	LOGIN: '/apis/v2/login',
	LOGOUT: '/apis/logout',
};

const ROLE_WITH_ACCESS = 'ROLE_STUDENT';
const NO_ACCESS_MESSAGE = 'This is a learner only platform';
const AUTH_TOKEN_KEY = 'auth-token';

const { AUTH_TOKEN_COOKIE } = cookieConstants;

export interface LoginAPIRequestPayload {
	username: string;
	password: string;
}

export async function post_loginUser(userData: LoginAPIRequestPayload) {
	try {
		const response = await authAPIAxiosInstance.post(URL.LOGIN, userData);
		const data: any = response.data;
		const hasStudentRole = data.roles.some(
			(item: any) => item.role === ROLE_WITH_ACCESS,
		);
		if (!hasStudentRole) {
			return Promise.reject({
				response: {
					data: {
						errorList: [{ errorMessage: NO_ACCESS_MESSAGE }],
					},
				},
			});
		}
		setCookie({
			[AUTH_TOKEN_COOKIE]: response.headers[AUTH_TOKEN_KEY],
		});
		return response;
	} catch (error) {
		rollbar.error(error);
		throw error;
	}
}

export async function post_logoutUser() {
	try {
		await authAPIAxiosInstance.post(URL.LOGOUT);
	} catch (error) {
		rollbar.error(error);
		throw error;
	}
}
