import { createReducer } from '@reduxjs/toolkit';
import {
	action_setServiceTypes,
	action_setBookingConfirmationDetails,
	action_setBookingModalDetails,
	action_setBookingSource,
	action_setDomainSelectionModalDetails,
	action_setSelectedDomain,
	action_setSelectedEventType,
	action_setServiceInfoModalDetails,
	action_setSessionCountModalDetails,
	action_setAccountModalDetails,
	action_setPolicyModalDetails,
	action_setDomainExperience,
	action_setUserResume,
	action_setUserSignedInOnEntry,
} from '../actions/jit';

const initialState = {
	serviceTypes: [],
	bookingConfirmationDetails: {},
	bookingModalDetails: {},
	bookingSource: '',
	domainSelectionModalDetails: {},
	selectedDomain: '',
	selectedEventType: {},
	serviceInfoModalDetails: {},
	sessionCountModalDetails: {},
	accountModalDetails: {},
	policyModalDetails: {},
	domainExperience: [],
	userResume: {},
	userSignedInOnEntry: false,
};

const jitReducer = createReducer(initialState, {
	[action_setServiceTypes]: (state, a) => {
		state.serviceTypes = a.payload;
	},
	[action_setBookingConfirmationDetails]: (state, a) => {
		state.bookingConfirmationDetails = a.payload;
	},
	[action_setBookingModalDetails]: (state, a) => {
		state.bookingModalDetails = a.payload;
	},
	[action_setBookingSource]: (state, a) => {
		state.bookingSource = a.payload;
	},
	[action_setDomainSelectionModalDetails]: (state, a) => {
		state.domainSelectionModalDetails = a.payload;
	},
	[action_setSelectedDomain]: (s, a) => {
		s.selectedDomain = a.payload;
	},
	[action_setSelectedEventType]: (state, a) => {
		state.selectedEventType = a.payload;
	},
	[action_setServiceInfoModalDetails]: (state, a) => {
		state.serviceInfoModalDetails = a.payload;
	},
	[action_setSessionCountModalDetails]: (state, a) => {
		state.sessionCountModalDetails = a.payload;
	},
	[action_setAccountModalDetails]: (state, a) => {
		state.accountModalDetails = a.payload;
	},
	[action_setPolicyModalDetails]: (state, a) => {
		state.policyModalDetails = a.payload;
	},
	[action_setDomainExperience]: (state, a) => {
		state.domainExperience = a.payload;
	},
	[action_setUserResume]: (state, a) => {
		state.userResume = a.payload;
	},
	[action_setUserSignedInOnEntry]: (state, a) => {
		state.userSignedInOnEntry = { ...state.userSignedInOnEntry, ...a.payload };
	},
});

export default jitReducer;
