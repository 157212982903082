// FIRST IMPORT REACT RELATED PACKAGES AND HOOKS
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { deleteCookie, setCookie, getCookie } from 'src/utils/cookies.js';
import { store } from 'src/store';

// THEN OTHER THIRD PARTY PACKAGES
import { Auth } from '@upgrad-accounts/flows';
import { envConstant } from 'src/constants/envConstant';

import '@upgrad-accounts/components/dist/main.css';
import '@upgrad-accounts/flows/dist/main.css';

// THEN UI RELATED COMPONENTS
import { Modal, Row, Col, Button } from 'antd';

// NEXT, THE API, STORE AND UTIL HELPERS
import { get_leadDetails, get_serviceTypes, post_dropLead } from 'src/apis';
import {
	action_setServiceTypes,
	action_setBookingModalDetails,
	action_setServiceInfoModalDetails,
	action_setAccountModalDetails,
	action_setPolicyModalDetails,
	action_setUserSignedInOnEntry,
	action_setBookingSource,
} from 'src/actions/jit';
import { action_loaderOn, action_loaderOff } from 'src/actions/global';
import { action_setUserData } from 'src/actions/user.js';

// NOW THE COMPONENTS
import { BookTimeslot } from 'src/components/BookTimeslot';
import { ErrorComponent } from 'src/components/ErrorComponent';
import { OfflineComponent } from 'src/components/OfflineComponent';
import { Loader } from 'src/components/Loader';
import { ServiceInfoModal } from 'src/components/ServiceInfoModal';
import { cookieConstants, globalConstant } from 'src/constants';
import {
	parseAndStoreUTMParams,
	clearUTMParamsFromCookie,
	retrieveUTMParams,
	splitCountryCodeFromPhoneNumber,
} from 'src/utils';
import { pushEvent, checkForCustomBaseProperties } from 'src/utils/segment';
import { AVAILABLE_SERVICES } from './modules/JIT/constants';
import routePaths from './routePaths';
import './sass/index.scss';

const { AUTH_TOKEN_COOKIE, LEAD_PLATFORM } = cookieConstants;

const JIT = React.lazy(() => import('./modules/JIT'));
const Logout = React.lazy(() => import('./modules/Logout'));

// const ROUTES_SUPPORTED_ON_MOBILE = [
// 	LOGOUT_ROUTE,
// 	ERROR_SCREEN_ROUTE,
// 	routePaths.JIT.route,
// 	routePaths.JIT_BookingSummaryPage.route,
// 	routePaths.JIT_ExpertList.route,
// 	routePaths.JIT_ExpertProfile.route,
// 	routePaths.JIT_Home.route,
// 	routePaths.JIT_MyBookings.route,
// 	routePaths.JIT_PostBookingSession.route
// ];
// FIXME: Viral to check for this solution

export const RouteWrapper = (props) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const serviceTypes = useSelector((s) => s.jit.serviceTypes);
	const authTokenCookieExists = Boolean(getCookie(AUTH_TOKEN_COOKIE));
	const userInfo = useSelector((s) => s.user);

	useEffect(() => {
		const checkIfUserInfoExists = async () => {
			if (authTokenCookieExists && !userInfo.id) {
				// This conditions means the user has signed in already and we need to drop
				// A lead later on in the funnel, if it hasn't already been dropped for the same touchpoint
				store.dispatch(
					action_setUserSignedInOnEntry({
						isSignedInLead: true,
						lastLeadTouchPoint: '',
					}),
				);
				const response = await get_leadDetails();
				const { leadIdentifier, user } = response;
				store.dispatch(
					action_setUserData({
						leadIdentifier: leadIdentifier,
						id: user.id,
						email: user.email,
						name: user.name,
						firstName: user.firstname,
						lastName: user.lastname,
						roles: user.roles,
						phoneNumber: user.phoneNumber,
						profilePic: user.profilePic,
					}),
				);
			}
		};
		checkIfUserInfoExists();
		parseAndStoreUTMParams(location.search);
	}, []);

	if (serviceTypes?.length) {
		return <Route {...props}>{props.children}</Route>;
	} else {
		return <></>;
	}
};

RouteWrapper.propTypes = {
	userInfo: PropTypes.object,
	path: PropTypes.string || PropTypes.object,
	protect: PropTypes.string,
	children: PropTypes.node,
	exact: PropTypes.bool,
};

const App = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [isSignup, setIsSignup] = useState(false);
	const [UTMParams, setUTMParams] = useState({});
	const [platformKey, setPlatformKey] = useState('');

	const userInfo = useSelector((s) => s.user);
	const serviceTypes = useSelector((s) => s.jit.serviceTypes);
	const loading = useSelector((s) => s.global.loader) > 0;
	const bookingModalDetails = useSelector((s) => s.jit.bookingModalDetails);
	const policyModalDetails = useSelector((s) => s.jit.policyModalDetails);
	const serviceInfoModalDetails = useSelector(
		(s) => s.jit.serviceInfoModalDetails,
	);
	const accountModalDetails = useSelector((s) => s.jit.accountModalDetails);

	useEffect(() => {
		const e = retrieveUTMParams();
		const platform = getCookie(LEAD_PLATFORM);
		setUTMParams(e);
		checkForCustomBaseProperties(location.search);
		// If base props have iOS or Android as the platform, we set it here
		if (platform) setPlatformKey(platform);
		else setPlatformKey(window.innerWidth < 768 ? 'mWeb' : 'dWeb');
	}, []);

	useEffect(() => {
		const checkIfServiceTypesExist = async () => {
			dispatch(action_loaderOn());
			// Any other API that is used to fetch launch data can be called here
			if (!serviceTypes?.length) {
				const data = await get_serviceTypes();
				dispatch(action_setServiceTypes(data));
			}
			dispatch(action_loaderOff());
		};
		checkIfServiceTypesExist();
	}, [serviceTypes, dispatch]);

	const handleModalDismiss = (modalType) => {
		switch (modalType) {
			case 'booking_modal':
				dispatch(action_setBookingModalDetails({}));
				pushEvent(
					'click_cross',
					{
						action: 'cross_click',
						section: 'calender',
					},
					true,
				);
				break;
			case 'service_info':
				dispatch(action_setServiceInfoModalDetails({}));
				break;
			case 'accounts_modal':
				dispatch(action_setAccountModalDetails({}));
				break;
			// case 'session_count':
			// 	dispatch(action_setSessionCountModalDetails({}))
			// 	break;
			case 'policy_modal':
				dispatch(action_setPolicyModalDetails({}));
				break;
			default:
				break;
		}
	};

	const fetchAndSubmitLeadId = async () => {
		// We call '/authenticate' to get leadId as well as user details
		const response = await get_leadDetails();
		const { leadIdentifier, user } = response;
		store.dispatch(
			action_setUserData({
				leadIdentifier: leadIdentifier,
				id: user.id,
				email: user.email,
				name: user.name,
				firstName: user.firstname,
				lastName: user.lastname,
				roles: user.roles,
				phoneNumber: user.phoneNumber,
				profilePic: user.profilePic,
			}),
		);
		// Leads are dropped manually only when users sign in
		if (!isSignup) {
			await post_dropLead({
				payload: {
					email: user.email,
					phoneNumber: user.phoneNumber,
					phone: splitCountryCodeFromPhoneNumber(user.phoneNumber),
					firstname: user.firstname,
					lastname: user.lastname,
					course: accountModalDetails?.course || 'Mentorship', // accountModalDetails?.course is for counsellor flow, where we are passing program of interest
					sendWelcomeMail: false,
					leadSource: {
						platform: platformKey,
						platformSection: accountModalDetails.platformSection,
					},
				},
				headers: UTMParams,
			});
		}
		pushEvent('lead_submitted', {
			category: accountModalDetails.category,
			user_flow: isSignup ? 'sign_up' : 'sign_in',
			service_type: accountModalDetails.serviceType,
			leadIdentifier: leadIdentifier,
			booking_amount: accountModalDetails.bookingAmount,
		});
		dispatch(action_setAccountModalDetails({}));
		clearUTMParamsFromCookie();
	};

	const loginUser = async (token) => {
		const payload = {
			[AUTH_TOKEN_COOKIE]: token,
		};
		setCookie(payload);
		fetchAndSubmitLeadId();
		accountModalDetails && accountModalDetails.callback();
	};
	return (
		<>
			{/* <OfflineComponent /> */}

			{Object.keys(accountModalDetails).length > 0 && (
				<Modal
					width={930}
					visible={true}
					footer={null}
					centered={true}
					onCancel={() => handleModalDismiss('accounts_modal')}
					className="service-info-modal"
					maskTransitionName=""
				>
					<Auth
						initialData={{
							additionalQuestions: {
								isVisible: false,
								isRequired: false,
								questions: [
									{
										label: 'Highest Qualification Completed',
										type: 'radio',
										options: [],
										key: 'leadEligibility',
									},
								],
							},
							isLoggedIn: false,
							active: true,
							width: 'auto',
							height: 'auto',
							showGenericAuth: false,
							programVariantKey: 'Mentorship',
							courseKey: 'Mentorship',
							programKey: 'Mentorship',
							programPackageKey: 'Mentorship',
							signupExtraFields: {
								leadSource: {
									platform: platformKey,
									platformCounsellor: '',
									platformSection: accountModalDetails.platformSection,
								},
								course: accountModalDetails?.course || 'Mentorship', // accountModalDetails?.course is for counsellor flow, where we are passing program of interest
								affiliateSource: 'Mentorship',
								sendWelcomeMail: false,
							},
							signupExtraHeaders: UTMParams,
							isCreateLead: true,
							createExternalLead: true,
						}}
						envConfig={{
							AUTH_BASE_URL: envConstant('authApiServerUrl'),
							CENTRAL_BASE_URL: envConstant('centralServerURL'),
							AUTHTOKEN_KEY: 'auth-token',
							MARKETING_URL: envConstant('marketingPlatformUrl'),
						}}
						handleRedirection={(token) => {
							// Called post login as well as signup
							loginUser(token);
						}}
						afterRegistration={() => {
							// Called only after signup
							setIsSignup(true);
						}}
						eventHandler={{
							trackEvent: 'trackEvent',
							trackView: 'trackView',
							identify: 'identify',
							tracker: {
								trackEvent: (props) => {
									pushEvent(props.name, {
										...props,
										lead_section: accountModalDetails.platformSection,
									});
								},
								trackView: (props) => {
									pushEvent(props.name, {
										...props,
										lead_section: accountModalDetails.platformSection,
									});
								},
							},
						}}
						eventProps={{
							page_url: window.location.href,
							program_package_key: 'Mentorship',
							page_title: 'Mentorship',
							device_type: window.innerWidth < 768 ? 'mWeb' : 'web',
							platform: window.innerWidth < 768 ? 'mWeb' : 'web',
							logged_in_flag: false,
							section: 'Mentorship',
							actions: 'Mentorship',
						}}
					/>
				</Modal>
			)}

			{
				<React.Suspense fallback={<Loader />}>
					<Switch>
						{/* 
						Commented by ssnk
						<Route path={routePaths.Login.route} exact>
							<Login />
						</Route> */}
						{/* Commented by Viral for SGC release - July 2021 */}
						<Route path={routePaths.Logout.route} exact>
							<Logout />
						</Route>
						<RouteWrapper path={routePaths.JIT.route} userInfo={userInfo}>
							<JIT />
						</RouteWrapper>
						<Route path={routePaths.ErrorScreen.route}>
							<ErrorComponent />
						</Route>
						<Redirect to={globalConstant.DEFAULT_LANDING_ROUTE} />
					</Switch>

					{Object.keys(bookingModalDetails).length > 0 && (
						<Modal
							width={930}
							visible={true}
							footer={null}
							centered={true}
							onCancel={() => handleModalDismiss('booking_modal')}
							style={{
								height: '100vh',
							}}
							className="book-timeslot-modal"
							maskTransitionName=""
						>
							<BookTimeslot
								slotData={{
									profile: {
										name: bookingModalDetails.expertName,
										company: bookingModalDetails.company,
										selectedSessionType: bookingModalDetails.sessionType,
										selectedSessionDuration: bookingModalDetails.sessionDuration,
										areaOfExpertise: bookingModalDetails.coreSkills,
										email: bookingModalDetails.email,
										id: bookingModalDetails.id,
										profilePictureURL: bookingModalDetails.profilePictureURL,
										timestamp: Date.now(),
										sessionPrice: bookingModalDetails?.sessionPrice,
										expertDomainName: bookingModalDetails?.expertDomain,
										designation: bookingModalDetails?.designation,
									},
								}}
							></BookTimeslot>
						</Modal>
					)}
					{/* Code below is required */}
					{/* {sessionCountModalDetails && Object.keys(sessionCountModalDetails).length > 0 && (
						<Modal
							width={460}
							visible={Object.keys(sessionCountModalDetails).length > 0}
							footer={null}
							centered={true}
							onCancel={() => handleModalDismiss('session_count')}
							className="session-count-modal"
							maskTransitionName=""
						>
							<SessionCountModal
								sessionCount={sessionCountModalDetails?.count}
								onClose={handleSessionCountModalDissmiss}
							/>
						</Modal>
					)} */}

					{serviceInfoModalDetails &&
						Object.keys(serviceInfoModalDetails).length > 0 && (
							<Modal
								width={930}
								visible={Object.keys(serviceInfoModalDetails).length}
								footer={null}
								centered={true}
								onCancel={() => handleModalDismiss('service_info')}
								className="service-info-modal"
								maskTransitionName=""
							>
								<ServiceInfoModal selectedService={serviceInfoModalDetails.type} />
							</Modal>
						)}

					{Object.keys(policyModalDetails).length > 0 && (
						<Modal
							width={694}
							visible={true}
							centered={true}
							onCancel={() => handleModalDismiss('policy_modal')}
							className="policy-detail-modal"
							maskTransitionName=""
							footer={null}
						>
							{/* TODO: Adarsh to convert this to component */}
							<h3 className="mb-16 text-center">
								Cancellation &amp; Rescheduling Policy
							</h3>
							<div className="policy-text-wrapper">
								<p>
									1. Once the session begins, the user must join within 10minutes else
									they would be assumed a no-show and the session would stand cancelled
									with no refund. However, the user is eligible for Rescheduling/Refund
									if the expert fails to join within 10 minutes the session starts.
								</p>
								<p>
									2. The user can reschedule the session by writing an email to the
									customer support at learnersupport@upgrad.com with the Subject Line -
									‘Reschedule: Booking ID ABCD’. This should happen at least 6 hours
									before the session begins. Any rescheduling request that is received in
									less than 6 hours of the session cannot be actioned upon.
								</p>
								<p>
									3. Rescheduling of the session would be done with the same mentor at a
									different date at the discretion of the admin.
								</p>
								<p>
									4. A session once booked cannot be cancelled. In case of cancellation
									no refund will be provided unless the expert fails to join within 10
									minutes the session starts.{' '}
								</p>
							</div>
							<Row justify={'center'}>
								<Col md={6}>
									<Button
										type="primary"
										onClick={() => handleModalDismiss('policy_modal')}
										className="fill-width mt-24"
									>
										Got it
									</Button>
								</Col>
							</Row>
						</Modal>
					)}
				</React.Suspense>
			}
			{loading && <Loader />}
		</>
	);
};

export default App;
