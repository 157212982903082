import { createAction } from '@reduxjs/toolkit';

export const action_setUserData = createAction('SET_USER_DETAILS', (data) => ({
	payload: {
		leadIdentifier: data.leadIdentifier,
		id: data.id,
		email: data.email,
		name: data.name,
		firstName: data.firstname,
		lastName: data.lastname,
		roles: data.rolesStringSet,
		phoneNumber: data.phoneNumber,
		profilePic: data.profilePic,
	},
}));
