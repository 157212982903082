import { envConstant } from 'src/constants/envConstant';
const env = envConstant('env');

export default Object.freeze({
	AUTH_TOKEN_COOKIE: `upgrad-auth-token.${env}`,
	COURSE_ID_COOKIE: `upgrad-career-last-active-course-id.${env}`,
	SESSION_ID_COOKIE: `upgrad-sessionId.${env}`,
	LEAD_IDENTIFIER: 'upgrad-lead-identifier',
	LEAD_PLATFORM: 'lead-platform',
});
