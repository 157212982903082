import { createAction } from '@reduxjs/toolkit';

export const action_setServiceTypes = createAction(
	'SET_AVAILABLE_EVENT_TYPES',
	(data) => ({ payload: data }),
);
export const action_setBookingConfirmationDetails = createAction(
	'SET_BOOKING_CONFIRMATION_DETAILS',
	(data) => ({ payload: data }),
);
export const action_setBookingModalDetails = createAction(
	'SET_BOOKING_MODAL_DETAILS',
	(data) => ({ payload: data }),
);
export const action_setBookingSource = createAction(
	'SET_BOOKING_SOURCE',
	(data) => ({ payload: data }),
);
export const action_setDomainSelectionModalDetails = createAction(
	'SET_DOMAIN_SELECTION_MODAL_DETAILS',
	(data) => ({ payload: data }),
);
export const action_setSelectedDomain = createAction(
	'SET_SELECTED_DOMAIN',
	(data) => ({ payload: data }),
);
export const action_setSelectedEventType = createAction(
	'SET_SELECTED_EVENT_TYPE',
	(data) => ({ payload: data }),
);
export const action_setServiceInfoModalDetails = createAction(
	'SET_SERVICE_INFO_MODAL_DETAILS',
	(data) => ({ payload: data }),
);
export const action_setSessionCountModalDetails = createAction(
	'SET_SESSION_COUNT_MODAL_DETAILS',
	(data) => ({ payload: data }),
);
export const action_setAccountModalDetails = createAction(
	'SET_ACCOUNT_MODAL_DETAILS',
	(data) => ({ payload: data }),
);
export const action_setPolicyModalDetails = createAction(
	'SET_POLICY_MODAL_DETAILS',
	(data) => ({ payload: data }),
);
export const action_setDomainExperience = createAction(
	'SET_DOMAIN_EXPERIENCE',
	(data) => ({ payload: data }),
);
export const action_setUserResume = createAction('SET_USER_RESUME', (data) => ({
	payload: data,
}));
export const action_setUserSignedInOnEntry = createAction(
	'SET_USER_SIGNED_IN_ON_ENTRY',
	(data) => ({
		payload: data,
	}),
);
