import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reduxBatch } from '@manaflair/redux-batch';

import {
	globalReducer,
	type_globalReducerState,
} from '../reducers/global.reducer';
import jitReducer from '../reducers/jit.reducer';
import userReducer from '../reducers/user.reducer';

const history = createBrowserHistory();
history.listen(() => {
	window.scrollTo(0, 0);
});

const store = configureStore({
	reducer: {
		global: globalReducer,
		jit: jitReducer,
		router: connectRouter(history),
		user: userReducer,
	},
	middleware: [...getDefaultMiddleware(), routerMiddleware(history)],
	devTools: process.env.NODE_ENV !== 'production',
	enhancers: [reduxBatch],
});

interface RootState {
	global: type_globalReducerState;
	jit: any;
	router: any;
	user: any;
}

export { store, history };
export type { RootState };
