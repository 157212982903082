import routePaths from 'src/routePaths';

interface type_pageMetaData {
	category?: string;
	pageTitle?: string;
	pageUrl?: string;
}

const urlPageMetaDataMapping = {
	[routePaths.JIT_Home.route]: {
		category: 'Microservices_Home',
		pageTitle: 'Home',
	},
	[routePaths.JIT_ExpertProfile.route.replace('/:expertID', '')]: {
		category: 'Microservices_E_profile',
		pageTitle: 'Expert Profile',
	},
	[routePaths.JIT_ExpertList.route]: {
		category: 'Microservices_E_list',
		pageTitle: 'Expert List',
	},
	[routePaths.JIT_PostBookingSession.route]: {
		category: 'Microservices_booking_confirmed',
		pageTitle: 'Booking Confirmation',
	},
	[routePaths.JIT_BookingSummaryPage.route]: {
		category: 'Microservices_booking_summary',
		pageTitle: 'Booking Summary',
	},
	[routePaths.JIT_MyBookings.route]: {
		category: 'Microservices_my_booking_screen',
		pageTitle: 'My Booking Screen',
	},
};

const pageUrls = Object.keys(urlPageMetaDataMapping);

const pageMetaData = () => {
	let currentLocation = window.location.pathname;
	//search the text in the urlPageMetaDataMapping...

	let pageMetaDataPayload: type_pageMetaData = {
		pageUrl: window.location.href,
		...urlPageMetaDataMapping[pageUrls[0]],
	};

	for (let i = 1; i < pageUrls.length; i++) {
		if (currentLocation.includes(pageUrls[i])) {
			Object.assign(pageMetaDataPayload, urlPageMetaDataMapping[pageUrls[i]]);
			return pageMetaDataPayload;
		}
	}

	return pageMetaDataPayload;
};

export { pageMetaData };
export type { type_pageMetaData };
