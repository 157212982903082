const splitCountryCodeFromPhoneNumber = (phoneNumber: string) => {
	if (phoneNumber?.length) {
		const recognisedCountryCodes = ['+91'];
		let res: any = {
			code: '+91',
			number: phoneNumber,
		};
		recognisedCountryCodes.forEach((code) => {
			if (code === phoneNumber.substring(0, code.length)) {
				res.code = phoneNumber.substring(0, code.length);
				res.number = phoneNumber.substring(code.length).trim();
			}
		});
		return res;
	} else return phoneNumber;
};

export default splitCountryCodeFromPhoneNumber;
