/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { format, parseISO, add } from 'date-fns';
import { Row, Col } from 'antd';

import { pageMetaData } from 'src/utils';
import { pushEvent } from 'src/utils/segment';
import './index.scss';

import arrowIcon from 'src/assets/images/icons/arrow.svg';

SwiperCore.use([Navigation]);

const TimeSlots = ({
	slotData,
	selectTimeSlot,
	expertId,
	sessionType,
	shouldResetSlot,
}) => {
	const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
	const [slotsTemplate, setSlotsTemplate] = useState([]);
	const selectSlot = (index, slot, startDateTime) => {
		setSelectedTimeSlot(index);
		selectTimeSlot(slot, startDateTime);
		// CT debug here
		pushEvent(
			'auth_date_book_prompt_selections',
			{
				user_flow: 'booking',
				section:
					pageMetaData().pageTitle === 'Expert List'
						? 'listing_page'
						: 'mentor_profile_page',
				action:
					pageMetaData().pageTitle === 'Expert List' ? 'Book a session' : 'Book Now',
				type: sessionType,
				selection_item: 'timestamp slot',
				selected_item_value: JSON.stringify(slot),
				expert_id: expertId,
			},
			true,
		);
	};

	useEffect(() => {
		if (shouldResetSlot) {
			setSelectedTimeSlot(null);
		}
	}, [shouldResetSlot]);

	useEffect(() => {
		const renderTemplate = [];
		slotData?.slots?.forEach((slotItem, index) => {
			const splitDate = slotData.date.split('-');
			const year = splitDate[0];
			const month = splitDate[1];
			const day = splitDate[2];
			const assembledDate = `${year}/${month}/${day}`;
			let startSlot = {
				utc: add(new Date(`${assembledDate} ${slotItem.startTime}`), {
					hours: 5,
					minutes: 30,
				}).toISOString(),
				key: format(parseISO(`${slotData.date} ${slotItem.startTime}`), 'hh:mm aa'),
			};

			renderTemplate.push(
				<span
					className={`timeslot ${selectedTimeSlot === index ? 'selected' : ''}`}
					key={slotItem.startTime}
					role=""
					onClick={() => {
						selectSlot(index, slotItem, startSlot.utc);
					}}
				>
					{startSlot.key}
				</span>,
			);
		});
		setSlotsTemplate(renderTemplate);
	}, [slotData, selectedTimeSlot]);

	return <div className="available-timeslots">{slotsTemplate}</div>;
};

// FIXME: Use treeshaking directly on props
export const DatePicker = (props) => {
	const {
		availableSlots,
		isTimeSlotPicked,
		expertId,
		sessionType,
		shouldResetSlot,
	} = props;
	const previousButton = useRef(null);
	const nextButton = useRef(null);
	const [currentlySelectedSlot, setCurrentlySelectedSlot] = useState(0);
	const [datePickerTemplates, setDatePickerTemplates] = useState([]);

	const handleSelectTimeslot = (slot, startDateTime) => {
		// Relays selected slot back to parent
		// FIXME: Use slotID to book a slot on BE
		props.slotSelected(startDateTime, slot.id);
		// CT debug here
		pushEvent(
			'auth_cta_activation',
			{
				user_flow: 'booking',
				section:
					pageMetaData().pageTitle === 'Expert List'
						? 'listing_page'
						: 'mentor_profile_page',
				action:
					pageMetaData().pageTitle === 'Expert List' ? 'Book a session' : 'Book Now',
				type: sessionType,
				expert_id: expertId,
			},
			true,
		);
	};

	const slotSelected = (selectedIndex) => {
		setCurrentlySelectedSlot(selectedIndex);
		// CT debug here
		pushEvent(
			'auth_date_book_prompt_selections',
			{
				user_flow: 'booking',
				section:
					pageMetaData().pageTitle === 'Expert List'
						? 'listing_page'
						: 'mentor_profile_page',
				action:
					pageMetaData().pageTitle === 'Expert List' ? 'Book a session' : 'Book Now',
				type: sessionType,
				selection_item: 'date slot',
				selected_item_value: JSON.stringify(availableSlots[selectedIndex].date),
				expert_id: expertId,
			},
			true,
		);
	};

	const handleNextPrevBtnClick = (type) => {
		pushEvent(
			'carousel',
			{
				component_title: 'Calendar',
				direction: type === 'next' ? 'right' : 'left',
			},
			true,
		);
	};

	useEffect(() => {
		let localDateTemplate = [];
		let isFirstSlotSelected = false;
		availableSlots.forEach((date, index) => {
			let dateItem = date.date;
			if (!isFirstSlotSelected && currentlySelectedSlot === 0 && date?.slots) {
				slotSelected(index);
				isFirstSlotSelected = true;
			}
			localDateTemplate.push(
				<SwiperSlide key={date.date}>
					<div
						className={cx('date-item', {
							current: index === currentlySelectedSlot,
							disabled: !date?.slots,
						})}
					>
						<span className="day">{format(new Date(dateItem), 'E')}</span>
						<div
							className="selector"
							onClick={() => {
								if (availableSlots[index]) {
									slotSelected(index);
								}
							}}
						>
							<span className="date">{format(new Date(dateItem), 'dd')}</span>
							<p className="month">{format(new Date(dateItem), 'LLL')}</p>
						</div>
					</div>
				</SwiperSlide>,
			);
		});
		setDatePickerTemplates(localDateTemplate);

		return () => {
			isFirstSlotSelected = false;
		};
	}, [availableSlots, currentlySelectedSlot]);

	return (
		<div className="date-picker-wrapper">
			<Row className="mv-24" justify={'space-between'} align={'middle'}>
				<h3>Choose Date</h3>
				<div className="datepicker-controls">
					<div
						className="previous mr-8"
						ref={previousButton}
						onClick={() => handleNextPrevBtnClick('previous')}
					>
						<img src={arrowIcon} alt="" />
					</div>
					<div
						className="next"
						ref={nextButton}
						onClick={() => handleNextPrevBtnClick('next')}
					>
						<img src={arrowIcon} alt="" />
					</div>
				</div>
			</Row>
			<Row>
				<Col className="mr-24 ml-8">
					<Swiper
						breakpoints={{
							320: {
								spaceBetween: 18,
								slidesPerView: 5,
							},
							768: {
								spaceBetween: 24,
								slidesPerView: 7,
							},
						}}
						loop={false}
						onInit={(swiper) => {
							/* eslint-disable no-param-reassign */
							// REASON: Safely re-assigning a function parameter
							swiper.params.navigation.prevEl = previousButton.current;
							swiper.params.navigation.nextEl = nextButton.current;
							swiper.navigation.init();
							swiper.navigation.update();
							/* eslint-enable no-param-reassign */
						}}
					>
						{datePickerTemplates}
					</Swiper>
				</Col>
			</Row>
			<hr className="hide-on-mobile mv-32" />
			{availableSlots[currentlySelectedSlot]?.slots?.length ? (
				<>
					<h3 className="mb-8">Choose timeslot</h3>
					<Row>
						{availableSlots.length && (
							<TimeSlots
								slotData={availableSlots[currentlySelectedSlot]}
								selectTimeSlot={handleSelectTimeslot}
								source={props.source}
								expertId={expertId}
								sessionType={sessionType}
								shouldResetSlot={shouldResetSlot}
							></TimeSlots>
						)}
					</Row>
					<Row className="hide-on-mobile">
						{!isTimeSlotPicked && (
							<span className="pick-slot-prompt mt-32">*Please pick a timeslot</span>
						)}
					</Row>
				</>
			) : (
				<>
					<p className="no-timeslots-text">No timeslots available</p>
				</>
			)}
		</div>
	);
};
