/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import { action_setUserData } from '../actions/user';

const initialState = {
	leadIdentifier: '',
	id: null,
	email: '',
	name: '',
	firstName: '',
	lastName: '',
	phoneNumber: null,
	roles: [],
	profilePic: '',
};

const userReducer = createReducer(initialState, {
	[action_setUserData]: (state, action) => {
		state.leadIdentifier = action.payload.leadIdentifier;
		state.id = action.payload.id;
		state.email = action.payload.email;
		state.name = action.payload.name;
		state.firstName = action.payload.firstName;
		state.lastName = action.payload.lastName;
		state.roles = action.payload.roles;
		state.phoneNumber = action.payload.phoneNumber;
		state.profilePic = action.payload.profilePic;
	},
});
export default userReducer;
