import { centralAPIAxiosInstance } from './utils/axios';
import rollbar from 'src/utils/rollbar';

const URL = {
	USER_INFO: '/apis/v2/authenticate/',
	LEAD_DROP: '/apis/v7/drop-lead',
};

export async function get_leadDetails() {
	try {
		const response = await centralAPIAxiosInstance.get(URL.USER_INFO);
		return response.data;
	} catch (error) {
		rollbar.error(error);
		throw error;
	}
}

export async function post_dropLead(params: any) {
	try {
		const response = await centralAPIAxiosInstance.post(
			URL.LEAD_DROP,
			params.payload,
			{
				headers: params.headers,
			},
		);
		return response.data;
	} catch (error) {
		rollbar.error(error);
		throw error;
	}
}
