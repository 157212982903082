import axios, { AxiosInstance } from 'axios';
import { notification } from 'antd';
import { envConstant } from 'src/constants/envConstant';
import { cookieConstants, apiHeaderConstants } from 'src/constants';
import routePaths from 'src/routePaths';
import { history } from 'src/store';
import { uniqueId } from 'src/utils';
import { getCookie, setCookie } from 'src/utils/cookies';

const { COURSE_ID_COOKIE, SESSION_ID_COOKIE, AUTH_TOKEN_COOKIE } =
	cookieConstants;

const {
	COURSE_ID_HEADER,
	SESSION_ID_HEADER,
	AUTH_TOKEN_HEADER,
	CAREER_PYTHON_SERVICE_COURSE_ID_HEADER,
} = apiHeaderConstants;

const getSessionID = () => {
	let sessionIDCookie = getCookie(SESSION_ID_COOKIE);
	if (!sessionIDCookie) sessionIDCookie = uniqueId();
	setCookie({ [SESSION_ID_COOKIE]: sessionIDCookie });
	return sessionIDCookie;
};

const setCourseAndSessionIdHeaders = (config: any, headers: any) => {
	const courseID = getCookie(COURSE_ID_COOKIE);
	if (courseID) {
		if (config.baseURL.includes(envConstant('careerPythonServiceBaseURL'))) {
			headers[SESSION_ID_HEADER] = getSessionID();
			headers[CAREER_PYTHON_SERVICE_COURSE_ID_HEADER] = courseID;
		} else {
			headers[COURSE_ID_HEADER] = courseID;
		}
	}
};

const beforeRequest = (config: any) => {
	let headersToBeAdded: any = {};
	const authTokenValue = getCookie(AUTH_TOKEN_COOKIE);
	if (authTokenValue) headersToBeAdded[AUTH_TOKEN_HEADER] = authTokenValue;
	setCourseAndSessionIdHeaders(config, headersToBeAdded);
	config.headers = { ...headersToBeAdded, ...config.headers };
	return config;
};

const createAxios = (baseURL: string): AxiosInstance => {
	const axiosInstance: AxiosInstance = axios.create({
		baseURL,
	});

	// Request Interceptor
	axiosInstance.interceptors.request.use(
		(config) => beforeRequest(config),
		(error) => Promise.reject(error),
	);

	// Response interceptor
	axiosInstance.interceptors.response.use(
		(response) => response,
		(error) => {
			const status = error.response?.status;
			switch (status) {
				case 400:
					// TO BE HANDLED BY INDIVIDUAL APIS
					// history.push(routePaths.JIT_Home.route);
					break;
				case 401:
					notification['error']({
						message: 'Your session has expired. Please login again.',
						className: 'body-only',
					});
					history.push(routePaths.Logout.route);
					break;
				case 403:
				case 404:
					history.push(`/error/${status}`);
					break;
				default:
					// This will handle all error codes except the ones mentioned above
					notification['error']({
						message: 'Something went wrong, please try again',
						className: 'body-only',
					});
					console.error(
						`Backend Error [${error.response?.status}] with API: `,
						error,
					);
					// history.push(routePaths.JIT_Home.route);
					break;
			}
			return Promise.reject(error);
		},
	);

	return axiosInstance;
};

export const crmAPIAxiosInstance = createAxios(envConstant('crmAPIServerURL'));
export const careerPythonServiceAxiosInstance = createAxios(
	envConstant('careerPythonServiceBaseURL'),
);
export const crmDetailsAxiosInstance = createAxios(
	envConstant('crmDetailsServerURL'),
);
export const authAPIAxiosInstance = createAxios(
	envConstant('authApiServerUrl'),
);
export const centralAPIAxiosInstance = createAxios(
	envConstant('centralServerURL'),
);

export const paymentApiAxiosInstance = createAxios(
	envConstant('paymentApiServerUrl'),
);
