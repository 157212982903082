function capitalize(str) {
	const words = str.split(' ');

	for (let i = 0; i < words.length; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
		if (i !== words.length - 1) {
			words[i] = words[i] + ' ';
		}
	}
	return words;
}

export default capitalize;
