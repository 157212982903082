import React from 'react';
import { Row, Col, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { action_setServiceInfoModalDetails } from 'src/actions/jit';
import './index.scss';
import { RootState } from 'src/store';

export const ServiceInfoModal = ({ selectedService }: any) => {
	const serviceTypes = useSelector((s: RootState) => s.jit.serviceTypes);
	const dispatch = useDispatch();

	const getCopyFromConstants = (type: string) => {
		const selectedServiceInfo = serviceTypes.filter(
			(i: any) => i.type === selectedService,
		)[0];
		return selectedServiceInfo[type];
	};

	const sessionUSPs = () => {
		let USPs: any = [];
		const USPData = getCopyFromConstants('usps');
		USPData.forEach((usp: any, index: number) => {
			USPs.push(
				<li key={index}>
					<img src={usp.icon} className="icon-30" alt="" />
					<span>{usp.text}</span>
				</li>,
			);
		});
		return <ul className="what-you-get-wrapper">{USPs}</ul>;
	};

	const handleInfoModalDismiss = () => {
		dispatch(action_setServiceInfoModalDetails({}));
	};
	return (
		<div className="service-info-wrapper">
			<Row>
				<Col span={24}>
					<h2>{getCopyFromConstants('name')} Session</h2>
				</Col>
			</Row>
			<Row className="mt-48">
				<Col md={7} xs={24}>
					<div
						className="thumbnail-wrapper"
						style={{
							backgroundImage: `url(${getCopyFromConstants('thumbnailURL')})`,
						}}
					></div>
				</Col>
				<Col md={17} xs={24}>
					<div className="content-section ml-40 mt-20">
						<h3 className="mb-16">What you get?</h3>
						{sessionUSPs()}
					</div>
				</Col>
			</Row>
			<Row align={'middle'} justify={'center'} className="cta-row">
				<Col md={6}>
					<Button
						type="primary"
						className="fill-width"
						onClick={handleInfoModalDismiss}
					>
						Got it
					</Button>
				</Col>
			</Row>
		</div>
	);
};
