import React from 'react';
import { history } from 'src/store';
import PropTypes from 'prop-types';
import rollbar from 'src/utils/rollbar';

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errorInfo: null,
		};
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			errorInfo,
		});
		rollbar.error('Error in Career Centre beta', {
			error,
			errorInfo,
		});
		// TODO: Add rollbar reporting here
	}

	render() {
		if (this.state.errorInfo) {
			return 'ERROR';
		}
		// Normally, just render children
		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.any,
};
