export const oldCareerCentreRoutes = {
	jobs: '/jobsv1/all',
	interviewPrep: '/preparation',
	login: '/login',
	account: '/account/education-and-work',
};

export const oldCareerCentreTabs = Object.freeze([
	{
		title: 'Jobs',
		route: oldCareerCentreRoutes.jobs,
	},
	{
		title: 'Interview Prep',
		route: oldCareerCentreRoutes.interviewPrep,
	},
]);
