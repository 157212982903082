export default Object.freeze({
	RESUME_REVIEW: {
		// RESUME REVIEW
		price: 599,
		beforeDiscount: 1199,
	},
	RESUME_REVIEW_PRO: {
		// RESUME REVIEW PRO
		price: 1299,
		beforeDiscount: 2099,
	},
	LINKEDIN_WRITING: {
		// LINKEDIN PROFILE WRITING
		price: 999,
		beforeDiscount: 1999,
	},
	HR_INTERVIEW_PREP: {
		// HR INTERVIEW PREPERATION
		price: 799,
		beforeDiscount: 1599,
	},
	RESUME_LINKEDIN_REVIEW: {
		// RESUME & LINKEDIN REVIEW
		price: 799,
		beforeDiscount: 1599,
	},
	RESUME_WRITING: {
		// RESUME WRITING
		price: 999,
		beforeDiscount: 1999,
	},
	MOCK_INTERVIEW: {
		// DOMAIN INTERVIEW PREP
		price: 1499,
		beforeDiscount: 2499,
	},
	STUCK_IN_CAREER: {
		// STUCK IN CAREERS
		price: 1499,
		beforeDiscount: 2499,
	},
	JOB_SEARCH: {
		// JOB SEARCH STRATEGY
		price: 1499,
		beforeDiscount: 2499,
	},
});
