import { envConstant } from 'src/constants/envConstant';

const setCookie = (obj, expiry = null) => {
	Object.keys(obj).forEach((key) => {
		const domainString =
			envConstant('env') === 'local'
				? ''
				: `domain=${envConstant('cookieDomain')}`;
		const expirySetting = expiry ? `; cookie=ok;expires=${expiry}` : '';
		document.cookie = `${key}=${obj[key]}; path=/; ${domainString}${expirySetting}`;
	});
};

const setCookieWithExpiry = (key, value, days) => {
	const domainString =
		envConstant('env') === 'local' ? '' : `domain=${envConstant('cookieDomain')}`;
	const date = new Date();
	const daysBeforeExpiry = days || 365;
	date.setTime(date.getTime() + daysBeforeExpiry * 86400000);
	document.cookie = `${key}=${value}; path=/;expires=${date.toGMTString()}; ${domainString}`;
};

const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop().split(';').shift();
	}
	return '';
};

const deleteCookie = (keys) => {
	keys.forEach((k) => {
		const domainString =
			envConstant('env') === 'local'
				? ''
				: `domain=${envConstant('cookieDomain')}`;
		document.cookie = `${k}=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domainString}`;
	});
};

export { setCookie, getCookie, deleteCookie, setCookieWithExpiry };
