export enum DOMAINS {
	ALL = 'ALL',
	DATA_SCIENCE = 'DATA_SCIENCE',
	DIGITAL_MARKETING = 'DIGITAL_MARKETING',
	PRODUCT_MANAGEMENT = 'PRODUCT_MANAGEMENT',
	CAREER_EXPERT = 'CAREER_EXPERT',
	CAREER_COUNSELLOR = 'CAREER_COUNSELLOR',
}

export enum BUTTONS {
	EXPLORE_SERVICES = 'Explore Services',
	VIEW_PROFILE = 'View Profile',
	BOOK_A_SESSION = 'Book a Session',
}

export enum BUTTON_ACTIONS {
	VIEW_PROFILE = 'view_profile',
	BOOK_A_SESSION = 'book_a_session',
}

export type DOMAINS_DATA = {
	id: number;
	key: DOMAINS;
	name: string;
	shouldCountForExperience: boolean;
	shouldShowUnderTopExperts: boolean;
	showExpertFilter: boolean;
	rampDownStrip: boolean;
	showServicePrice: boolean;
	primaryButtonAction?: BUTTON_ACTIONS;
	primaryButtonName?: BUTTONS;
	secondaryButtonAction?: BUTTON_ACTIONS;
	secondaryButtonName?: BUTTONS;
	showCompany: boolean;
	noPayment: boolean;
};

export type AVAILABLE_DOMAINS_TYPE = { [key in DOMAINS]: DOMAINS_DATA };

export const AVAILABLE_DOMAINS: AVAILABLE_DOMAINS_TYPE = Object.freeze({
	ALL: {
		id: 0,
		key: DOMAINS.ALL,
		name: 'All Specialties',
		rampDownStrip: true,
		secondaryButtonAction: BUTTON_ACTIONS.VIEW_PROFILE,
		secondaryButtonName: BUTTONS.VIEW_PROFILE,
		shouldCountForExperience: true,
		shouldShowUnderTopExperts: true,
		showCompany: true,
		showExpertFilter: true,
		showServicePrice: true,
		noPayment: false,
	},
	DATA_SCIENCE: {
		id: 1,
		key: DOMAINS.DATA_SCIENCE,
		name: 'Data Science',
		rampDownStrip: true,
		secondaryButtonAction: BUTTON_ACTIONS.VIEW_PROFILE,
		secondaryButtonName: BUTTONS.VIEW_PROFILE,
		shouldCountForExperience: true,
		shouldShowUnderTopExperts: true,
		showCompany: true,
		showExpertFilter: true,
		showServicePrice: true,
		primaryButtonAction: BUTTON_ACTIONS.BOOK_A_SESSION,
		primaryButtonName: BUTTONS.BOOK_A_SESSION,
		noPayment: false,
	},
	DIGITAL_MARKETING: {
		id: 2,
		key: DOMAINS.DIGITAL_MARKETING,
		name: 'Digital Marketing',
		rampDownStrip: true,
		secondaryButtonAction: BUTTON_ACTIONS.VIEW_PROFILE,
		secondaryButtonName: BUTTONS.VIEW_PROFILE,
		shouldCountForExperience: true,
		shouldShowUnderTopExperts: true,
		showCompany: true,
		showExpertFilter: true,
		showServicePrice: true,
		noPayment: false,
	},
	PRODUCT_MANAGEMENT: {
		id: 3,
		key: DOMAINS.PRODUCT_MANAGEMENT,
		name: 'Product Management',
		rampDownStrip: true,
		secondaryButtonAction: BUTTON_ACTIONS.VIEW_PROFILE,
		secondaryButtonName: BUTTONS.VIEW_PROFILE,
		shouldCountForExperience: true,
		shouldShowUnderTopExperts: true,
		showCompany: true,
		showExpertFilter: true,
		showServicePrice: true,
		noPayment: false,
	},
	CAREER_EXPERT: {
		id: 3,
		key: DOMAINS.CAREER_EXPERT,
		name: 'Career Expert',
		rampDownStrip: true,
		secondaryButtonAction: BUTTON_ACTIONS.VIEW_PROFILE,
		secondaryButtonName: BUTTONS.VIEW_PROFILE,
		shouldCountForExperience: true,
		shouldShowUnderTopExperts: false,
		showCompany: true,
		showExpertFilter: true,
		showServicePrice: true,
		noPayment: false,
	},
	CAREER_COUNSELLOR: {
		id: 5,
		key: DOMAINS.CAREER_COUNSELLOR,
		name: 'Career Counsellor',
		primaryButtonAction: BUTTON_ACTIONS.BOOK_A_SESSION,
		primaryButtonName: BUTTONS.BOOK_A_SESSION,
		rampDownStrip: true,
		shouldCountForExperience: false,
		shouldShowUnderTopExperts: false,
		showCompany: false,
		showExpertFilter: false,
		showServicePrice: false,
		noPayment: true,
	},
});

export enum SERVICES {
	COUNSELLING_SESSION = 'COUNSELLING_SESSION',
	RESUME_WRITING = 'RESUME_WRITING',
	HR_INTERVIEW_PREP = 'HR_INTERVIEW_PREP',
	JOB_SEARCH = 'JOB_SEARCH',
	RESUME_REVIEW = 'RESUME_REVIEW',
	RESUME_REVIEW_PRO = 'RESUME_REVIEW_PRO',
	LINKEDIN_WRITING = 'LINKEDIN_WRITING',
	MOCK_INTERVIEW = 'MOCK_INTERVIEW',
	STUCK_IN_CAREER = 'STUCK_IN_CAREER',
	RESUME_LINKEDIN_REVIEW = 'RESUME_LINKEDIN_REVIEW',
}

export type SERVICES_DATA = {
	show: boolean;
};

export type AVAILABLE_SERVICES_TYPE = { [key in SERVICES]: SERVICES_DATA };

export const AVAILABLE_SERVICES: AVAILABLE_SERVICES_TYPE = {
	COUNSELLING_SESSION: {
		show: false,
	},
	RESUME_WRITING: {
		show: true,
	},
	HR_INTERVIEW_PREP: {
		show: true,
	},
	JOB_SEARCH: {
		show: true,
	},
	RESUME_REVIEW: {
		show: true,
	},
	RESUME_REVIEW_PRO: {
		show: true,
	},
	LINKEDIN_WRITING: {
		show: true,
	},
	MOCK_INTERVIEW: {
		show: true,
	},
	STUCK_IN_CAREER: {
		show: true,
	},
	RESUME_LINKEDIN_REVIEW: {
		show: true,
	},
};

export const SUPPORT_EMAIL_ID = 'learnersupport@upgrad.com';
export const SUPPORT_PHONE_NUMBER = '+919321961848';
