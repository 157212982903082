import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

// const time = '2019-10-25T08:10:00Z';

const formatInTimeZone = (date, fmt, tz) =>
	format(utcToZonedTime(date, tz), fmt, { timeZone: tz });

export const formatToUTC = (time) => {
	console.log(time);
	const parsedTime = parseISO(time);
	console.log(parsedTime); // 2019-10-25T08:10:00.000Z

	return formatInTimeZone(parsedTime, 'dd/MM/yyyy kk:mm:ss', 'UTC');
};
