import { parseUrlParams } from './index';
import { add, addDays } from 'date-fns';
import { getCookie, setCookie, deleteCookie } from 'src/utils/cookies.js';

const UTM_PARAMS = [
	{
		key: 'utm_campaign',
		storeKey: 'mentorship-utm_campaign',
	},
	{
		key: 'utm_content',
		storeKey: 'mentorship-utm_content',
	},
	{
		key: 'utm_medium',
		storeKey: 'mentorship-utm_medium',
	},
	{
		key: 'utm_source',
		storeKey: 'mentorship-utm_source',
	},
	{
		key: 'utm_term',
		storeKey: 'mentorship-utm_term',
	},
];

const parseAndStoreUTMParams = (location: string) => {
	const cookieExpiry = addDays(new Date(), 30);
	const queryObject: any = parseUrlParams(location);
	let resultObj: { [index: string]: any } = {};
	UTM_PARAMS.forEach((param: any) => {
		if (queryObject[param.key]?.length) {
			const { storeKey, key } = param;
			resultObj[storeKey] = queryObject[key];
		}
	});
	setCookie(resultObj, cookieExpiry);
	return resultObj;
};

const clearUTMParamsFromCookie = () => {
	const keysToDelete = UTM_PARAMS.map((item) => item.storeKey);
	deleteCookie(keysToDelete);
};

const retrieveUTMParams = () => {
	let resultObj: { [index: string]: any } = {};
	UTM_PARAMS.forEach((param: any) => {
		const { key, storeKey } = param;
		const paramValue = getCookie(storeKey);
		if (paramValue) resultObj[key] = paramValue;
	});
	return resultObj;
};

export { parseAndStoreUTMParams, clearUTMParamsFromCookie, retrieveUTMParams };
