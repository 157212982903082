module.exports = {
	JIT: {
		route: '/mentorship',
		routeName: 'Base',
	},
	JIT_Home: {
		route: '/mentorship/',
		routeName: 'Home',
	},
	JIT_ExpertProfile: {
		route: '/mentorship/expert/profile/:expertID',
		routeName: 'Viewing Expert Profile',
	},
	JIT_ExpertList: {
		route: '/mentorship/expert/list',
		routeName: '',
	},
	JIT_PostBookingSession: {
		route: '/mentorship/booking/confirmation',
		routeName: '',
	},
	JIT_BookingSummaryPage: {
		route: '/mentorship/booking/summary',
		routeName: 'Booking Summary',
	},
	JIT_MyBookings: {
		route: '/mentorship/my-bookings',
		routeName: 'My Bookings',
	},
	Logout: {
		route: '/mentorship/logout',
		routeName: '',
	},
	ErrorScreen: {
		route: '/mentorship/error/:code',
		routeName: '',
	},
};
