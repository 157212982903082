import { createAction } from '@reduxjs/toolkit';

export const action_loaderOff = createAction('LOADER_OFF');
export const action_loaderOn = createAction('LOADER_ON');
export const action_setOffline = createAction(
	'SET_OFFLINE',
	(data: boolean) => ({
		payload: data,
	}),
);
