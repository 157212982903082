import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { addDays, add, format } from 'date-fns';
import { Row, Col, Button } from 'antd';
import queryString from 'query-string';
import { notification } from 'antd';

import {
	action_setBookingModalDetails,
	action_setBookingConfirmationDetails,
	action_setAccountModalDetails,
	action_setPolicyModalDetails,
	action_setSelectedDomain,
	action_setUserSignedInOnEntry,
} from 'src/actions/jit';
import { action_loaderOn, action_loaderOff } from 'src/actions/global';
import {
	get_availableTimeslots,
	//post_bookSession,
	checkSlotBlok,
	get_expertProfile,
	post_orderCreation,
} from 'src/apis';
import { envConstant } from 'src/constants/envConstant';
import routePaths from 'src/routePaths';
import { store } from 'src/store';
import { pageMetaData, formatTimeDuration, handleLeadDrop } from 'src/utils';
import { pushEvent } from 'src/utils/segment';

import { DatePicker } from '../DatePicker';
import './index.scss';

import { AVAILABLE_DOMAINS } from 'src/modules/JIT/constants';
import makePayment from '../../modules/JIT/Payment/payment-iframe';

import expertPlaceholder from 'src/assets/images/placeholders/profile-placeholder.png';
import durationVioletIcon from 'src/assets/images/icons/duration-violet.svg';
import companyVioletIcon from 'src/assets/images/icons/company-violet.svg';
import { formatToUTC } from 'src/utils/formatToUTC';

export const BookTimeslot = ({ slotData }) => {
	let history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	const selectedEventType = useSelector((s) => s.jit.selectedEventType);
	const userSignedInOnEntry = useSelector((s) => s.jit.userSignedInOnEntry);
	const userInfo = useSelector((s) => s.user);
	const [availableSlots, setAvailableSlots] = useState([]);
	const [selectedSlotStart, setSelectedSlotStart] = useState('');
	const [selectedSlotId, setSelectedSlotId] = useState('');
	const [sessionDuration, setSessionDuration] = useState('');
	const [isFetchingSlots, setIsFetchingSlots] = useState(true);
	const [shouldResetSlot, setShouldResetSlot] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [domainData, setDomainData] = useState('');
	const { profile } = slotData;

	const params = useMemo(() => queryString.parse(location.search), [location]);

	useEffect(() => {
		(async () => {
			let data;
			const dateNow = add(new Date(), {
				hours: 5,
				minutes: 30,
			});
			if (selectedEventType.id) {
				if (
					localStorage.getItem('timeSlotDebug') &&
					envConstant('env') !== 'production'
				) {
					data = await get_availableTimeslots({
						consumerId: slotData.profile.id,
						endDate: addDays(dateNow, 15).toISOString().split('T')[0],
						startDate: dateNow.toISOString().split('T')[0],
						eventId: selectedEventType.id,
					});
					setIsFetchingSlots(false);
				} else {
					data = await get_availableTimeslots({
						consumerId: slotData.profile.id,
						endDate: addDays(dateNow, 17).toISOString().split('T')[0],
						startDate: addDays(dateNow, 2).toISOString().split('T')[0],
						eventId: selectedEventType.id,
					});
					setIsFetchingSlots(false);
				}

				let sessionString = formatTimeDuration(selectedEventType.duration);
				setSessionDuration(sessionString);

				let fullDatesRange = [];
				for (let i = 0; i <= 15; i++) {
					let rangeEndDate = add(new Date(), { days: i })
						.toISOString()
						.split('T')[0];
					fullDatesRange.push({
						date: rangeEndDate,
					});
				}

				fullDatesRange.forEach((dateItem) => {
					data.forEach((incompleteDate) => {
						if (dateItem.date === incompleteDate.date) {
							dateItem.slots = incompleteDate.slots;
						}
					});
					if (dateItem?.date === new Date().toISOString().split('T')[0]) {
						if (dateItem.slots) {
							const { slots } = dateItem;
							slots.forEach((slot, index) => {
								let startHour = slot.startTime.split(':')[0];
								let startMin = slot.startTime.split(':')[1];
								let currentTime = format(new Date(), 'kk:mm:ss');
								let currentHour = currentTime.split(':')[0];
								let currentMin = currentTime.split(':')[1];
								// FIXME: why is this being done on FE? Please get it done from BE if possbile
								if (
									startHour < currentHour ||
									(startHour === currentHour && startMin <= currentMin)
								) {
									delete slots[index];
								}
							});
						}
					}
				});
				setAvailableSlots(fullDatesRange);
			}
		})();
	}, [slotData, selectedEventType]);

	useEffect(() => {
		(async () => {
			let domain = profile.expertDomainName;
			if (!domain) {
				const response = await get_expertProfile(profile.id);
				domain = response.currentIndustry;
			}
			// BE Sends domain as a name under "currentIndustry" in the expert profile. We convert domain name into keys
			let result = Object.values(AVAILABLE_DOMAINS).filter((item) => {
				return item.name === domain;
			});
			const domainData = result[0];
			setDomainData(domainData);
			dispatch(action_setSelectedDomain(domainData?.key));
		})();
	}, [profile]);

	const handleSlotSelected = (startDateTime, slotId) => {
		setSelectedSlotStart(startDateTime);
		setSelectedSlotId(slotId);
	};

	const AreasOfExpertise = ({ tags }) => {
		let skillsTemplate = [];
		tags.forEach((skill) => {
			skillsTemplate.push(
				<span className="tag" key={skill.value}>
					{skill.value}
				</span>,
			);
		});
		return skillsTemplate;
	};

	const handleBookingConfirmation = async (label) => {
		setIsLoading(true);
		const { user } = store.getState();
		pushEvent(
			'auth_cta_click',
			{
				user_flow: 'booking',
				section:
					pageMetaData().pageTitle === 'Expert List'
						? 'listing_page'
						: 'mentor_profile_page',
				action:
					pageMetaData().pageTitle === 'Expert List' ? 'Book a session' : 'Book Now',
				type: selectedEventType.name,
				expert_id: slotData.profile.id,
			},
			true,
			// CT debug enabled here
		);
		pushEvent(
			'payment_cta_click',
			{
				button_name: `Book Now for ₹${profile.sessionPrice}`,
				type: selectedEventType.name,
				expert_id: slotData.profile.id,
			},
			true,
			// CT debug enabled here
		);

		if (!user.id) {
			setIsLoading(false);
			dispatch(
				action_setAccountModalDetails({
					isLoggedIn: false,
					bookingAmount: profile.sessionPrice,
					serviceType: selectedEventType.type,
					category: 'booking',
					platformSection: 'Mentorship_end_funnel',
				}),
			);
		} else {
			if (domainData.noPayment) {
				// added for counselling session
				dispatch(action_loaderOn());
				const slotPayload = {
					amount: 0,
					consumerEmail: slotData.profile.email,
					currency: 'INR',
					eventId: selectedEventType.id,
					slotId: selectedSlotId,
				};
				setShouldResetSlot(false);
				console.log(formatToUTC(selectedSlotStart), 'selectedSlotStart');
				console.log(formatToUTC(new Date().toISOString()), 'appointmentBookedOn');
				try {
					const response = await post_orderCreation(slotPayload);
					if (response.status === 'BOOKED') {
						if (selectedSlotStart) {
							let dateToFormat = selectedSlotStart.split('.')[0];
							console.log(formatToUTC(dateToFormat), 'dateToFormat');
							if (dateToFormat) {
								let appointmentDate = formatToUTC(dateToFormat);
								await handleLeadDrop({
									userInfo,
									course: params?.programOfInterest ?? profile?.designation,
									currentLeadTouchpoint: 'Mentorship_On_Book_Now',
									platformCounsellor: profile?.email,
									extraFields: {
										appointmentDate: appointmentDate,
										appointmentBookedOn: formatToUTC(new Date().toISOString()),
										eventType: 'Mentorship_On_Book_Now',
									},
								});
								notification['success']({
									message: 'Booking Successful',
									description: 'Our Counsellor will contact you',
								});
								params.redirectUrl
									? window.open(params.redirectUrl, '_self')
									: window.open(`${envConstant('marketingPlatformUrl')}/blog`, '_self');
							} else {
								console.log('error in selected date format', dateToFormat);
							}
						}
					}
				} catch (e) {
					console.log('error on /block api', e);
					if (e && e?.response?.data?.success === false) {
						notification['error']({
							message:
								e?.response?.data?.errorList[0].errorMessage ?? 'Error on Booking',
							description: 'Please book different slot.',
						});
					}
				}
				dispatch(action_loaderOff());
				setIsLoading(false);
			} else {
				const { isSignedInLead, lastLeadTouchPoint } = userSignedInOnEntry;
				const currentLeadTouchpoint = 'Mentorship_end_funnel';
				if (isSignedInLead && lastLeadTouchPoint !== currentLeadTouchpoint) {
					dispatch(
						action_setUserSignedInOnEntry({
							lastLeadTouchPoint: currentLeadTouchpoint,
						}),
					);
					await handleLeadDrop({
						userInfo,
						currentLeadTouchpoint,
					});
				}

				dispatch(action_loaderOn());
				const { user } = store.getState();

				var paymentData = {
					user: user,
					email: slotData.profile.email,
					eventId: selectedEventType.id,
					slotId: selectedSlotId,
					sessionPrice: profile.sessionPrice,
					paydescription: selectedEventType.name,
				};
				setShouldResetSlot(false);
				var paymentDetails = await makePayment(paymentData);
				if (paymentDetails.status === 'CANCELLED_BY_USER') {
					setSelectedSlotStart('');
					setShouldResetSlot(true);
					dispatch(action_loaderOff());
					setIsFetchingSlots(false);
					setIsLoading(false);
					pushEvent('payment_modal_dismissed');
				} else if (
					paymentDetails.bookingId &&
					paymentDetails.razorpayOrderId &&
					paymentDetails.status !== 'FAILED'
				) {
					const response = await checkSlotBlok(
						paymentDetails.bookingId,
						paymentDetails.razorpayOrderId,
					);
					if (response.meetingDetails) {
						bookingConfirmed(response);
					} else {
						bookingFailed('Booking Failed');
					}
					dispatch(action_loaderOff());
				} else {
					setIsFetchingSlots(true);
					dispatch(action_loaderOn());
					bookingFailed('Booking Failed');
					dispatch(action_loaderOff());
					setIsFetchingSlots(false);
				}
			}
		}
	};

	const bookingFailed = async (label) => {
		pushEvent(
			'user_session_booking_failed',
			{
				user_flow: 'booking',
				section:
					pageMetaData().pageTitle === 'Expert List'
						? 'listing_page'
						: 'mentor_profile_page',
				action:
					pageMetaData().pageTitle === 'Expert List' ? 'Book a session' : 'Book Now',
				type: selectedEventType.name,
				expert_id: slotData.profile.id,
				payment_amount: profile.sessionPrice,
			},
			true,
		);
		pushEvent(
			'payment_failure',
			{
				type: selectedEventType.name,
				expert_id: slotData.profile.id,
				payment_amount: profile.sessionPrice,
			},
			true,
		);

		dispatch(
			action_setBookingConfirmationDetails({
				name: profile.name,
				selectedSessionType: selectedEventType.name,
				startDateTime: selectedSlotStart,
				currentOrganization: profile.company,
				bookingId: '',
				expertId: profile.id,
				sessionType: selectedEventType.name,
				profilePictureURL: profile.profilePictureURL,
				isBookingConfirmed: false,
				areaOfExpertise: profile.areaOfExpertise,
				expertEmail: profile.email,
				expertDomain: domainData.key,
				sessionPrice: profile.sessionPrice,
			}),
		);
		dispatch(action_loaderOff());
		dispatch(action_setBookingModalDetails({}));
		history.push(routePaths.JIT_PostBookingSession.route);
	};

	const handlePolicyClick = () => {
		pushEvent(
			'cancellation_reschedule_policy_click',
			{
				user_flow: 'booking',
				section: 'booking_page',
				action:
					pageMetaData().pageTitle === 'Expert List' ? 'Book a session' : 'Book Now',
				type: selectedEventType.name,
				expert_id: slotData.profile.id,
				page_title: pageMetaData().pageTitle,
				page_category: pageMetaData().category,
				page_url: pageMetaData().pageUrl,
			},
			true,
		);

		dispatch(
			action_setPolicyModalDetails({
				shouldShow: true,
			}),
		);
		pushEvent('ms_modal_view', {
			modal_type: 'policy_modal',
		});
	};

	const bookingConfirmed = async (response) => {
		var data = response.meetingDetails;
		//If payment success slot will be blocked
		const fullName = [
			data.salutation != null ? data.salutation : '',
			data.cosumerFirstName != null ? data.cosumerFirstName : '',
			data.consumerLastName != null ? data.consumerLastName : '',
		];
		const nameString = fullName.join(' ').replace(/  +/g, ' ').trim();

		pushEvent(
			'user_session_booking_confirmation',
			{
				user_flow: 'booking',
				section:
					pageMetaData().pageTitle === 'Expert List'
						? 'listing_page'
						: 'mentor_profile_page',
				action:
					pageMetaData().pageTitle === 'Expert List' ? 'Book a session' : 'Book Now',
				type: selectedEventType.name,
				expert_id: slotData.profile.id,
				payment_amount: profile.sessionPrice,
			},
			true,
		);

		pushEvent(
			'payment_success',
			{
				type: selectedEventType.name,
				expert_id: slotData.profile.id,
				payment_amount: profile.sessionPrice,
			},
			true,
		);

		dispatch(
			action_setBookingConfirmationDetails({
				name: nameString,
				selectedSessionType: data.sessionType,
				startDateTime: data.sessionTime,
				currentOrganization: data.currentOrgnisation,
				bookingId: response.bookingId,
				expertId: data.consumerId,
				sessionType: data.sessionType,
				profilePictureURL: data.profilePictureURL,
				isBookingConfirmed: true,
				areaOfExpertise: profile.areaOfExpertise,
				expertDomain: domainData?.key,
			}),
		);

		dispatch(action_setBookingModalDetails({}));
		setIsLoading(false);
		history.push(routePaths.JIT_PostBookingSession.route);
	};

	return isFetchingSlots ? (
		<div className="book-timeslot-wrapper loading">
			<svg className="spinner" viewBox="0 0 50 50">
				<circle
					className="spinner_path"
					cx="25"
					cy="25"
					r="20"
					fill="none"
					strokeWidth="5"
				/>
			</svg>
		</div>
	) : (
		<div className="book-timeslot-wrapper">
			<Row gutter={30}>
				<Col span={9} className="bordered-divider pr-18 is-not-mobile-responsive">
					<div className="expert-profile-wrapper">
						<div
							className="profile-picture"
							style={{
								backgroundImage: `url(${
									profile.profilePictureURL ?? expertPlaceholder
								}`,
							}}
						></div>
						<div className="details">
							<h5>{profile.name}</h5>
							{domainData?.showCompany && profile.company.length > 0 && (
								<span>
									<img src={companyVioletIcon} alt="" />
									Works at {profile.company}
								</span>
							)}
						</div>
					</div>
					<div className="session-info">
						<span className="type">{selectedEventType.name}</span>
						<span className="duration">
							<img src={durationVioletIcon} alt="" />
							{sessionDuration}
						</span>
					</div>

					{profile.areaOfExpertise.length > 0 && (
						<>
							<h6>Areas of Expertise</h6>
							<div className="expertise-tags">
								<AreasOfExpertise tags={profile.areaOfExpertise}></AreasOfExpertise>
							</div>
						</>
					)}
				</Col>
				<Col span={15} className="cta-section mobile-responsive-col">
					<h5 className="hide-on-mobile">Choose Date &amp; Timeslot</h5>
					<DatePicker
						source="mentor_profile_page"
						slotSelected={handleSlotSelected}
						availableSlots={availableSlots}
						isTimeSlotPicked={selectedSlotStart}
						expertId={slotData.profile.id}
						sessionType={selectedEventType.name}
						shouldResetSlot={shouldResetSlot}
					></DatePicker>
					<Row className="mt-120 is-mobile-responsive-cta" justify={'space-between'}>
						<Col md={12}>
							<p className="policy-text">
								By continuing, you agree to upGrad’s{' '}
								<span className="policy-cta" onClick={handlePolicyClick}>
									Rescheduling policy
								</span>
							</p>
						</Col>
						<Col>
							<Button
								className="confirm-booking-btn"
								type="primary"
								onClick={() => handleBookingConfirmation('Confirm Booking')}
								disabled={!selectedSlotStart}
								loading={isLoading}
							>
								{selectedEventType.type && domainData?.showServicePrice
									? `Book Now for ${
											profile.sessionPrice ? `₹${profile.sessionPrice / 100}` : 'Free'
									  }`
									: 'Book Now'}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
