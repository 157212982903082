import {
	crmAPIAxiosInstance,
	crmDetailsAxiosInstance,
	paymentApiAxiosInstance,
} from '../utils/axios';
import { postExperienceApiPayload } from './request_models';
import { store } from 'src/store';
import rollbar from 'src/utils/rollbar';

const URL = {
	EXPERT_LIST: '/consumer/v2/profile/list',
	EXPERT_PROFILE: '/consumer/v2/profile/:id',
	EXPERT_RATINGS: '/consumer/profile/review/:id/list/',
	SERVICE_TYPES: '/event/eventlist/EXTERNAL',
	AVAILABLE_SLOTS: '/slotManagement/timeslots',
	BOOK_SESSION: '/meeting/create',
	MEETING_LIST: '/learner/meetinglist/:emailId',
	UPDATE_RESUME: '/learner/storeResumeDetails',
	RESUME_DETAILS: '/learner/getResumeDetails/:emailId',
	EXPERIENCE_DETAILS: '/learner/getExpDetails/:emailId',
	UPDATE_EXPERIENCE: 'learner/storeExpDetails',
	BOOKING_SUMMARY: 'learner/meetingsummary/:emailId?meetingId=:meetingId',
	PAYMENT_BOOKING: '/slotManagement/v2/block',
	PAYMENT_CONFIRMATION: '/validate/payment',
	CHECK_PAYMENT_STATUS: '/slotManagement/bookingStatus/:bookingId/:orderId',
};

export async function get_expertProfile(expertId: number) {
	try {
		let requestURL = URL.EXPERT_PROFILE.replace(':id', expertId.toString());
		const { data } = await crmAPIAxiosInstance.get(requestURL);
		if (data && data.profilePictureURL) {
			data.profilePictureURL = encodeURI(data.profilePictureURL);
		}
		data.servicesAvailable = data.servicesAvailable.filter(
			(service: any) => service.salesPrice > 0,
		);
		return data;
	} catch (error) {
		rollbar.error(error);
	}
}

export async function get_expertsList(
	page: number,
	size: number,
	domain?: string,
	eventType?: string | null,
	sort?: string,
) {
	try {
		let paramObject: any = {
			domain: domain,
			page: page,
			size: size,
			sort: sort,
		};
		if (eventType) paramObject.eventType = eventType;
		let { data, headers } = await crmAPIAxiosInstance.get(URL.EXPERT_LIST, {
			params: paramObject,
		});
		// let expertsLength = data.length;
		if (data.length) {
			data = data.map((expertData: any) => {
				expertData.profilePictureURL = expertData.profilePictureURL
					? encodeURI(expertData.profilePictureURL)
					: null;
				return expertData;
			});
			// For counselling sessions we are not filtering the free service as all the experts are free
			// data = data.filter(
			// 	(profile: any) =>
			// 		profile.servicesAvailable.filter((service: any) => service.salesPrice > 0)
			// 			.length > 0,
			// );
		}
		return {
			total_pages: headers.total_pages,
			total_count: headers.total_count,
			data: data,
			// free_services_count: expertsLength - data.length, // to count the experts who have 0 rupee price and remove them from overall expert count // This is for existing flow, but removing it for counselling booking, as all the counselling sessions are free
		};
	} catch (error) {
		rollbar.error(error);
	}
}

export async function get_serviceTypes() {
	try {
		const { data } = await crmAPIAxiosInstance.get(`${URL.SERVICE_TYPES}`);
		return data;
	} catch (error) {
		rollbar.error(error);
	}
}

export async function get_expertReviews(expertID: number, params: any) {
	try {
		const requestURL = URL.EXPERT_RATINGS.replace(':id', `${expertID}`);
		const { data, headers } = await crmAPIAxiosInstance.get(requestURL, {
			params: params,
		});
		return {
			total_pages: headers.total_pages,
			data: data,
		};
	} catch (error) {
		rollbar.error(error);
	}
}

export async function get_availableTimeslots(params: any) {
	try {
		const { data } = await crmAPIAxiosInstance.get(URL.AVAILABLE_SLOTS, {
			params: params,
		});
		return data;
	} catch (error) {
		rollbar.error(error);
	}
}

export async function post_bookSession(payload: any) {
	try {
		const { data } = await crmAPIAxiosInstance.post(URL.BOOK_SESSION, payload);
		return data;
	} catch (error) {
		rollbar.error(error);
		return error;
	}
}

export async function get_bookedSessions(type: string) {
	try {
		const { user } = store.getState();
		const requestURL = URL.MEETING_LIST.replace(':emailId', user.email);
		const { data } = await crmDetailsAxiosInstance.get(
			`${requestURL}${type ? '/' + type : ''}`,
		);
		if (data.data?.length) {
			data.data = data.data.map((item: any) => {
				item.profilePictureURL = item.profilePictureUrl
					? encodeURI(item.profilePictureUrl)
					: null;
				return item;
			});
		}
		return data.data;
	} catch (error) {
		rollbar.error(error);
	}
}

export async function post_resumeLink(resumeLink: string) {
	try {
		const { user } = store.getState();
		const { data } = await crmDetailsAxiosInstance.post(URL.UPDATE_RESUME, {
			resumeLink: resumeLink,
			learnerEmail: user.email,
		});
		return data;
	} catch (error) {
		rollbar.error(error);
	}
}

export async function get_resumeDetails() {
	try {
		const { user } = store.getState();
		const requestURL = URL.RESUME_DETAILS.replace(':emailId', user.email);
		const { data } = await crmDetailsAxiosInstance.get(requestURL);
		return data;
	} catch (error) {
		rollbar.error(error);
	}
}

export async function get_experienceDetails(domain?: string) {
	try {
		const { user } = store.getState();
		let params: any = {
			domain: domain ? domain.toUpperCase() : 'DATA_SCIENCE',
		};
		const parsedUrl = URL.EXPERIENCE_DETAILS.replace(':emailId', user.email);
		const { data } = await crmDetailsAxiosInstance.get(parsedUrl, {
			params: params,
		});
		return data;
	} catch (error) {
		rollbar.error(error);
	}
}

export async function post_updateExperience(experienceDetails: any) {
	try {
		// FIXME: Payload for domain in this function varies based on booking flow
		const { user } = store.getState();
		let domainString = '';
		if (typeof experienceDetails.domain !== 'string') {
			domainString = experienceDetails.domain.key.toUpperCase();
		} else {
			domainString = experienceDetails.domain.toUpperCase();
		}
		let experiencePayload: postExperienceApiPayload = {
			domain: domainString,
			experienceDisplayValue: experienceDetails.experienceDisplayValue,
			experienceValue: experienceDetails.experienceValue,
			learnerEmail: user.email,
			format: 'YEARS',
		};
		const { data } = await crmDetailsAxiosInstance.post(
			URL.UPDATE_EXPERIENCE,
			experiencePayload,
		);
		return data;
	} catch (error) {
		rollbar.error(error);
		throw error;
	}
}

export async function get_bookingSummary(meetingId: number) {
	try {
		const { user } = store.getState();
		let parsedUrl = URL.BOOKING_SUMMARY.replace(':emailId', user.email);
		parsedUrl = parsedUrl.replace(':meetingId', meetingId.toString());
		const { data } = await crmDetailsAxiosInstance.get(parsedUrl);
		return data;
	} catch (error) {
		rollbar.error(error);
	}
}

export async function post_orderCreation(orderDetails: any) {
	try {
		const { data } = await crmAPIAxiosInstance.post(
			URL.PAYMENT_BOOKING,
			orderDetails,
		);
		return data;
	} catch (error) {
		rollbar.error(error);
		throw error;
	}
}

export async function post_paymentConfirmation(paymentDetails: any) {
	try {
		const { data } = await paymentApiAxiosInstance.post(
			URL.PAYMENT_CONFIRMATION,
			paymentDetails,
		);
		return data;
	} catch (error) {
		rollbar.error(error);
		return error;
	}
}

export async function checkSlotBlok(bookingId: any, orderId: any) {
	try {
		var params = '?bookingId=' + bookingId + '&orderId=' + orderId;
		const parsedUrl = URL.CHECK_PAYMENT_STATUS.replace(
			'/:bookingId/:orderId',
			params,
		);
		const { data } = await crmAPIAxiosInstance.get(parsedUrl);
		if (data && data.meetingDetails) {
			data.meetingDetails.profilePictureURL = data.meetingDetails.profilePictureURL
				? encodeURI(data.meetingDetails.profilePictureURL)
				: null;
		}
		return data;
	} catch (error) {
		rollbar.error(error);
	}
}
