import { post_dropLead } from './../apis';
import { retrieveUTMParams, splitCountryCodeFromPhoneNumber } from 'src/utils';

import { getCookie } from 'src/utils/cookies.js';

import { cookieConstants } from 'src/constants';
import { pushEvent } from './segment';
const { LEAD_PLATFORM } = cookieConstants;

const handleLeadDrop = async (data: any) => {
	const { leadIdentifier, email, phoneNumber, firstName, lastName } =
		data.userInfo;
	const UTMParams = retrieveUTMParams();
	let platform = getCookie(LEAD_PLATFORM);
	if (!platform) platform = window.innerWidth < 768 ? 'mWeb' : 'dWeb';

	await post_dropLead({
		payload: {
			email: email,
			phoneNumber: phoneNumber,
			phone: splitCountryCodeFromPhoneNumber(phoneNumber),
			firstname: firstName,
			lastname: lastName,
			course: data?.course || 'Mentorship', // accountModalDetails?.course is for counsellor flow, where we are passing program of interest
			sendWelcomeMail: false,
			leadSource: {
				platform: platform,
				platformSection: data.currentLeadTouchpoint,
				platformCounsellor: data?.platformCounsellor,
			},
			extraFields: data?.extraFields,
			interestSource: data?.interestSource,
		},
		headers: UTMParams,
	});

	pushEvent('signed_in_lead_submitted', {
		category: 'signed_in',
		user_flow: data.currentLeadTouchpoint,
		leadIdentifier: leadIdentifier,
	});
};

export default handleLeadDrop;
