import { careerPythonServiceAxiosInstance } from './utils/axios';
import rollbar from 'src/utils/rollbar';

const URL = {
	RESUME: '/user/resume',
};

export async function post_uploadResume(action: any, payload: any) {
	try {
		// SessionId is hardcoded - New auth service has deprecated sessionId. Pyhton service will deprecate it too
		const headers = {
			'Content-Type': 'multipart/form-data',
			sessionId: 'abc-def-ghi',
		};
		let suffix = '';
		if (action === 'keepBoth') {
			suffix = '?replace=0';
		} else if (action === 'replaceExisting') {
			suffix = '?replace=1';
		}
		//FIXME: use addParam instead of string
		const { data } = await careerPythonServiceAxiosInstance.post(
			`${URL.RESUME}${suffix}`,
			payload,
			{
				headers,
			},
		);
		return data;
	} catch (error) {
		rollbar.error(error);
		throw error;
	}
}
