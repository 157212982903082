/* eslint-disable no-param-reassign */
// import rollbar from 'src/utils/rollbar';
import platform from 'platform';
import { envConstant } from 'src/constants/envConstant';
import { shouldShowCTDebugAlert } from '.';
import { store } from 'src/store';
import { pageMetaData } from 'src/utils';

let baseuserProps = null;
let loggedInUserProps = null;
let isProfileSetup = false;
let customBaseProperties = null;

export async function setupBaseProperties() {
	baseuserProps = {
		devicetype:
			envConstant('desktopBreakPoint') >= window.innerWidth ? 'mobile' : 'web',
		width: `${window.innerWidth}px`,
		height: `${window.innerHeight}px`,
		browser: platform.name, // 'Safari'
		browser_version: platform.version, // '5.1'
		device_manufacturer: platform.manufacturer, // 'Apple'
		device_model: platform.product, // 'iPad'
		device_os: platform.os.family,
		device_os_version: platform.os.version,
	};
}

export async function setupUser() {
	const { user } = store.getState();
	const { id, email, name, leadIdentifier } = user;

	if (id) {
		setupProfile(name, email);
		loggedInUserProps = {
			user_id: id,
			email: email,
			leadIdentifier: leadIdentifier,
		};
		isProfileSetup = true;
	}
}

export function pushEvent(name, data = {}, options = {}, debugFlag = true) {
	if (typeof window.analytics !== 'undefined' && name !== undefined) {
		const pageMetaDataForCT = pageMetaData();
		if (!isProfileSetup) setupUser();
		if (!baseuserProps) setupBaseProperties();

		try {
			const eventProperties = {
				...data,
				...baseuserProps,
				...loggedInUserProps,
				...customBaseProperties,
				page_title: pageMetaDataForCT.pageTitle,
				page_category: pageMetaDataForCT.category,
				page_url: pageMetaDataForCT.pageUrl,
			};

			let keys = printKeys(eventProperties);
			let alertString = `event: [ ${name} ] \n\n payload: ` + keys;
			if (shouldShowCTDebugAlert()) {
				alert(alertString);
			}

			// TRACK method of Segment
			window.analytics.track(name, eventProperties, options);
		} catch (e) {
			console.log('error: on emitting segment event', e);
			// rollbar.error('Segment Tracker Failed: ', e);
		}
	} else {
		return;
	}
}

// To set 'customBaseProperties' variable
export function checkForCustomBaseProperties(query) {
	// customBaseProperties are added as a global attribute if it exists
	// This is primarily used in webviews on native android and iOS apps
	const hasCustomPropQuery =
		query?.length && query.includes('?customBaseProperties=');
	if (hasCustomPropQuery) {
		const customProps = query.split('?customBaseProperties=');
		try {
			customBaseProperties = JSON.parse(decodeURIComponent(customProps[1]));
		} catch {
			// In case JSON inside the URL is not valid, do nothing
		}
	}
}

function printKeys(data) {
	let s = '';
	for (let x in data) {
		s += x + ' - [ ' + data[x] + ' ]\n';
	}
	return s;
}

export function setupProfile(name, email) {
	window.analytics.identify(email, {
		name: name,
		email: email,
	});
}
