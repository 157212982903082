import routePaths from 'src/routePaths';
export default Object.freeze({
	DEFAULT_LANDING_ROUTE: routePaths.JIT.route,
	FLOW: {
		COUNSELLING: {
			key: 'counselling',
			showExpertFilter: false,
		},
	},
});
